import Card from "../../Layouts/Card";
import {ReactComponent as Group} from "../../assets/group.svg";
import {useNavigate} from "react-router-dom";
import Table from "../shared/Table/Table";
import {useEffect, useMemo, useState} from "react";
import ThumbnailWithText from "../shared/Table/ThumbnailWithText";
import ActionsButtons from "../shared/Table/ActionsButtons";
import "./UsersPage.scss"
import * as actions from "./UsersActions"
import DeleteModal from "../shared/Modals/DeleteModal";
import Icon from "../shared/Icon";

const UsersPage = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [deleteModal, setDeleteModal] = useState(null)

    useEffect(() => {
        actions.loadUsers(users => setUsers(users));
    }, [])

    const handleDelete = cellData => {
        const id = cellData.value
        const user = cellData.data.find(u => u.id === id);
        setDeleteModal({
            id, name: user["full_name"], action: "Delete",
        })
    }


    const columns = useMemo(() => [
        {
            Header: "User",
            accessor: "full_name",
            Cell: cell => {
                const photo = cell.row.original["photo"]
                return <ThumbnailWithText text={cell.value} imgSrc={photo ? photo["image_url"] : null} isUserAvatar/>
            },
        },
        {
            Header: "Options",
            accessor: "id",
            Cell: cell => {
                return <ActionsButtons onEdit={() => navigate(`/user/${cell.value}`)} onDelete={() => handleDelete(cell)}/>
            }
        }
    ], [])

    return (
        <Card
            icon={<Icon icon={<Group/>} className="group-icon"/>}
            subtitle="Users"
            className="users-page"
            onClick={() => navigate("/user")}
            buttonText="+ Add new"
        >
            <Table data={users} columns={columns}/>
            {
                deleteModal &&
                <DeleteModal
                    onDelete={() => {
                        actions.deleteUser(deleteModal.id, () => {
                            setDeleteModal(null)
                            setUsers(prevState => prevState.filter(user => user.id !== deleteModal.id))
                        })
                    }}
                    onCancel={() => setDeleteModal(null)}
                    actionName={deleteModal.action}
                    itemName={deleteModal.name}
                />
            }
        </Card>
    )
}

export default UsersPage