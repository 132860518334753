const units = [
    {
        "value": "teaspoon",
        "label": "Teaspoon"
    },
    {
        "value": "tablespoon",
        "label": "Tablespoon"
    },
    {
        "value": "cup",
        "label": "Cup"
    },
    {
        "value": "gallon",
        "label": "Gallon"
    },
    {
        "value": "milliliter",
        "label": "Milliliter"
    },
    {
        "value": "liter",
        "label": "Liter"
    },
    {
        "value": "gram",
        "label": "Gram"
    },
    {
        "value": "kilogram",
        "label": "Kilogram"
    },
    {
        "value": "ounce",
        "label": "Ounce"
    },
    {
        "value": "pound",
        "label": "Pound"
    },
    {
        "value": "dash",
        "label": "Dash"
    },
    {
        "value": "pinch",
        "label": "Pinch"
    },
    {
        "value": "clove",
        "label": "Clove"
    },
    {
        "value": "can",
        "label": "Can"
    },
    {
        "value": "slice",
        "label": "Slice"
    },
    {
        "value": "piece",
        "label": "Piece"
    },
    {
        "value": "stick",
        "label": "Stick"
    },
    {
        "value": "package",
        "label": "Package"
    },
    {
        "value": "quantity",
        "label": "Quantity"
    }
]

export default units