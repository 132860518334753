import Card from "../../Layouts/Card";
import * as actions from "./TagsAction"
import {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import Table from "../shared/Table/Table";
import DeleteModal from "../shared/Modals/DeleteModal";
import Icon from "../shared/Icon";
import {ReactComponent as Tag} from "../../assets/tag.svg";
import './TagsPage.scss'
import ActionsButtons from "../shared/Table/ActionsButtons";


const TagsPage = () => {
    const [tags, setTags] = useState([]);
    const [deleteModal, setDeleteModal] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        actions.loadTags(tags => setTags(tags))
    }, []);

    const handleDelete = (cellData) => {
        const id = cellData.value;
        const tag = cellData.data.find(c => c.id === id);
        setDeleteModal({
            id: id,
            action: "Delete",
            name: tag.name,
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: "Tags",
                accessor: "name",
            },
            {
                Header: "Options",
                accessor: "id",
                Cell: cell => {
                    return <ActionsButtons
                        onEdit={() => navigate(`/tag/${cell.value}`)}
                        onDelete={() => handleDelete(cell)}
                    />
                }
            }
        ],
        []
    );


    return (
        <Card
            onClick={() => navigate("/tag")}
            buttonText="+ Add new"
            className="tags-page"
            icon={<Icon icon={<Tag/>} className="tag-icon"/>}
            subtitle="Tags"
        >
            <Table data={tags} columns={columns}/>
            {deleteModal &&
                <DeleteModal
                    onDelete={() => {
                        actions.deleteTag(deleteModal.id, () => {
                            setDeleteModal(null)
                            setTags(prevState => prevState.filter(tag => tag.id !== deleteModal.id))
                        })
                    }}
                    onCancel={() => {
                        setDeleteModal(null)
                    }}
                    itemName={deleteModal.name}
                    actionName={deleteModal.action}
                />
            }
        </Card>
    )
}

export default TagsPage