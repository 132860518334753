import axios from "axios";

export const loadTags = (callback) => {
    const config = {
        url: "/tags",
        method: "GET",
    }

    axios.request(config).then(response => {
        callback(response.data);
    })
}

export const deleteTag = (id, callback) => {
    const config = {
        url: `/tags/${id}`,
        method: "DELETE",
    };

    axios.request(config).then(() => callback())
};


