import "./Input.scss";
import classNames from "classnames";

const Input = ({formik, name, type, className}) => {

    const validationStatus = formik.touched[name] && formik.errors[name]

    const classNamesForInput = classNames("input", {
        "validation-error": validationStatus,
        [className]: className
    })

    return (
        <input
            type={type}
            name={name}
            onChange={formik.handleChange}
            onBlur={formik.onBlur}
            value={formik.values[name]}
            placeholder={validationStatus ? formik.errors[name] : null}
            className={classNamesForInput}
        />
    )
};

export default Input;
