import Modal from "./ModalTemplate/Modal";
import Button from "../Button";

const DeleteModal = ({onCancel, actionName, itemName, showButtonCancel, onDelete}) => {
    return (
        <Modal
            onCancel={onCancel}
            actionName={actionName}
            name={itemName}
            showButtonCancel={showButtonCancel}
            button={
                <Button onClick={onDelete} type={"button"} buttonText={"Delete"} variant="primary"/>
            }
        >
            <span>
                Are you sure that you want to delete: <span className="bold">{itemName}</span>?
            </span>
        </Modal>
    );
};

export default DeleteModal;
