import "./InputGroup.scss";
import classNames from "classnames";

const InputGroup = ({children, className}) => {


    return <div className={classNames("input-group", {[className]: className})}>{children}</div>;
};

export default InputGroup;
