import "./Label.scss";
import classNames from "classnames";

const Label = ({name, isRequired = false, alignTextStart = false, className}) => {
    const labelClass = classNames({
        label: true,
        required: isRequired,
        "text-start": alignTextStart,
        [className]: className,
    });

    return (
        <label htmlFor={name} className={labelClass}>
            {name}
        </label>
    );
};

export default Label;