import Card from "../../Layouts/Card";
import {useNavigate} from "react-router-dom";
import Icon from "../shared/Icon";
import {ReactComponent as Mortar} from "../../assets/mortar.svg";
import Table from "../shared/Table/Table";
import {useEffect, useMemo, useState} from "react";
import "./RecipesPage.scss"
import * as actions from "./RecipesActions";
import ThumbnailWithText from "../shared/Table/ThumbnailWithText";
import ActionsButtons from "../shared/Table/ActionsButtons";
import DeleteModal from "../shared/Modals/DeleteModal";

const RecipesPage = () => {
    const navigate = useNavigate();
    const [recipes, setRecipes] = useState([]);
    const [deleteModal, setDeleteModal] = useState()

    useEffect(() => {
        actions.loadRecipes(recipes => setRecipes(recipes));
    }, [])

    const handleDelete = cellData => {
        const id = cellData.value;
        const recipe = cellData.data.find(r => r.id === id);
        setDeleteModal({
            id,
            action: "Delete",
            name: recipe.name,
        })
    }


    const columns = useMemo(() => [
        {
            Header: "Recipe",
            accessor: "name",
            Cell: cell => {
                const photo = cell.row.original["photo"]
                return <ThumbnailWithText text={cell.value} imgSrc={photo ? photo["image_url"] : null}/>
            }
        },
        {
            Header: "Published",
            accessor: "created_at",
            Cell: cell => {
                const date = new Date(cell.value);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                const formattedDate = `${day}/${month}/${year}`;
                return <span>{formattedDate}</span>
            }
        },
        {
            Header: "Options",
            accessor: "id",
            Cell: cell => (
                <ActionsButtons
                    onEdit={() => navigate(`/recipe/${cell.value}`)}
                    onDelete={() => handleDelete(cell)}
                />
            )
        }
    ], [])

    return (
        <Card
            subtitle="Recipe"
            className="recipes-page"
            buttonText="+ Add new"
            onClick={() => navigate("/recipe")}
            icon={<Icon icon={<Mortar/>} className="mortar-icon"/>}
        >
            <Table data={recipes} columns={columns}/>
            {
                deleteModal &&
                <DeleteModal
                    onDelete={() => {
                        actions.deleteRecipe(deleteModal.id, () => {
                            setDeleteModal(null)
                            setRecipes(prevState => prevState.filter(recipe => recipe.id !== deleteModal.id))
                        })
                    }}
                    onCancel={() => {
                        setDeleteModal(null)
                    }}
                    actionName={deleteModal.action}
                    itemName={deleteModal.name}
                />
            }
        </Card>
    )
}

export default RecipesPage