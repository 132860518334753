import Card from "../../Layouts/Card";
import {useParams} from "react-router";
import Icon from "../shared/Icon";
import {ReactComponent as Carrot} from "../../assets/carrot.svg";
import {FileInput, Form, Input, InputGroup, Label} from "../shared/FormComponents/Form";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as actions from './IngredientActions'
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import FormSelect from "../shared/FormComponents/FormSelect";
import './IngredientPage.scss'
import DeleteModal from "../shared/Modals/DeleteModal";
import * as _ from "lodash";
import units from "./ingredientBasicUnits";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    unit: Yup.object().shape({
        value: Yup.string().required("Unit is required"),
    })
});

const IngredientPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [deleteModal, setDeleteModal] = useState(null)

    const formik = useFormik({
        initialValues: {
            id: null,
            name: "",
            unit: "",
            photo: null,
        },
        validationSchema,
        onSubmit: (values) => {
            actions.saveIngredient({
                ..._.omit(values, "photo"),
                unit: values.unit.value,
                photo_id: _.has(values.photo, "id") ? values.photo.id : null,
            }, () => {
                navigate("/ingredients")
            })
        }
    })

    useEffect(() => {
        if (id) {
            actions.loadIngredient(id, ingredient => {
                formik.setValues(
                    {
                        ..._.omit(ingredient, "unit"),
                        unit: {
                            value: ingredient.unit,
                            label: ingredient.unit.charAt(0).toUpperCase() + ingredient.unit.slice(1)
                        }
                    }
                )
                if (_.has(ingredient, "photo")) {
                    formik.setFieldValue("photo", {...ingredient.photo})
                }
            })
        }
    }, [id]);


    return (
        <Card
            icon={<Icon icon={<Carrot/>} className="carrot-icon"/>}
            title="Ingredients"
            subtitle={id ? "> Edit" : "> New"}
            buttonText="Save"
            onClick={() => formik.handleSubmit()}
            className="ingredient-page"
        >
            <Form>
                <InputGroup>
                    <Label name="Name" isRequired/>
                    <Input
                        type="text"
                        name="name"
                        formik={formik}
                    />
                </InputGroup>
                <InputGroup>
                    <Label name="Units" isRequired/>
                    <FormSelect name="unit" formik={formik} selectOptions={units}/>
                </InputGroup>
                <InputGroup>
                    <Label name="Photo"/>
                    <FileInput
                        onDelete={() => {
                            setDeleteModal({
                                name: formik.values.photo.name,
                                action: "Delete"
                            })
                        }}
                        formik={formik}
                    />
                </InputGroup>
            </Form>
            {
                deleteModal &&
                <DeleteModal
                    onDelete={() => {
                        formik.setFieldValue("photo", null)
                        setDeleteModal(null)
                    }}
                    onCancel={() => {
                        setDeleteModal(null)
                    }}
                    itemName={deleteModal.name}
                    actionName={deleteModal.action}
                />
            }
        </Card>
    )
}

export default IngredientPage;