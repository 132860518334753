import Card from "../../Layouts/Card";
import './IngredientsPage.scss'
import * as actions from './IngredientsAction'
import {useEffect, useMemo, useState} from "react";
import Table from "../shared/Table/Table";
import ThumbnailWithText from "../shared/Table/ThumbnailWithText";
import {useNavigate} from "react-router-dom";
import DeleteModal from "../shared/Modals/DeleteModal";
import Icon from "../shared/Icon";
import {ReactComponent as Carrot} from "../../assets/carrot.svg";
import ActionsButtons from "../shared/Table/ActionsButtons";

const IngredientsPage = () => {
    const [ingredients, setIngredients] = useState([]);
    const [deleteModal, setDeleteModal] = useState(null)
    const navigate = useNavigate();


    const handleDelete = (cellData) => {
        const id = cellData.value;
        const ingredient = cellData.data.find(i => i.id === id);
        setDeleteModal({
            id: id,
            action: "Delete",
            name: ingredient.name,
        })
    }

    useEffect(() => {
        actions.loadIngredients(ingredients => setIngredients(ingredients));
    }, [])

    const columns = useMemo(
        () => [
            {
                Header: "Ingredients",
                accessor: "name",
                Cell: cell => {
                    const photo = cell.row.original["photo"]
                    return <ThumbnailWithText text={cell.value} imgSrc={photo ? photo["image_url"] : null}/>
                }
            },
            {
                Header: "Units",
                accessor: "unit",
            },
            {
                Header: "Options",
                accessor: "id",
                Cell: cell => (
                    <ActionsButtons
                        onEdit={() => navigate(`/ingredient/${cell.value}`)}
                        onDelete={() => handleDelete(cell)}
                    />
                )
            }
        ],
        []
    );
    return (
        <Card
            subtitle="Ingredients"
            buttonText="+ Add new"
            className="ingredients-page"
            onClick={() => navigate("/ingredient")}
            icon={<Icon icon={<Carrot/>} className="carrot-icon"/>}
        >
            <Table data={ingredients} columns={columns}/>
            {
                deleteModal &&
                <DeleteModal
                    onDelete={() => {
                        actions.deleteIngredient(deleteModal.id, () => {
                            setDeleteModal(null)
                            setIngredients(prevState => prevState.filter(ingredient => ingredient.id !== deleteModal.id))
                        })
                    }}
                    itemName={deleteModal.name}
                    onCancel={() => setDeleteModal(null)}
                    actionName={deleteModal.action}
                />
            }
        </Card>
    )
}

export default IngredientsPage