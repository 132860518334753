import {loadCategories} from "../Categories/CategoriesAction";
import {loadTags} from "../Tags/TagsAction";
import {loadIngredients} from "../Ingredients/IngredientsAction";
import axios from "axios";


const loadRecipe = (id, callback) => {
    const config = {
        url: `/recipes/${id}`,
        method: 'GET',
    }

    axios.request(config).then(response => {
        callback(response.data);
    })
}

const saveRecipe = (recipe, callback) => {
    const config = {
        url: recipe.id ? `/recipes/${recipe.id}` : '/recipes',
        method: recipe.id ? "PUT" : "POST",
        data: {recipe}
    }

    axios.request(config).then(() => {
        callback()
    });
}


export {loadCategories, loadTags, loadRecipe, saveRecipe, loadIngredients};