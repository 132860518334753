import axios from "axios";

export const loadIngredient = (id, callback) => {
    const config = {
        url: `/ingredients/${id}`,
        method: "GET",
    }

    axios.request(config).then(response => callback(response.data))
}

export const saveIngredient = (ingredient, callback) => {
    const config = {
        method: ingredient.id ? "PUT" : "POST",
        url: ingredient.id ? `/ingredients/${ingredient.id}` : "/ingredients",
        data: {ingredient}
    }
    axios.request(config).then(() => callback())
}