import "./FormSelect.scss";
import classNames from "classnames";
import Select from "react-select"

const FormSelect = ({name, formik, selectOptions = [], isMulti = false, className}) => {
    const validationStatus = formik.touched[name] && formik.errors[name]

    const classNameForSelect = classNames("form-select", {
        "validation-error": validationStatus,
        "multi-select": isMulti,
        [className]: className
    });


    return (
        <Select
            options={selectOptions}
            value={formik.values[name]}
            menuPortalTarget={document.body}
            styles={{
                menuPortal: defaultStyles => ({
                    ...defaultStyles,
                    zIndex: 9999
                })
            }}
            onChange={newValues => formik.setFieldValue(name, newValues)}
            placeholder={validationStatus ? formik.errors[name].value : null}
            className={classNameForSelect}
            classNamePrefix="select"
            name={name}
            isMulti={isMulti}
        />
    );
};

export default FormSelect;
