import Button from "../Button";
import Icon from "../Icon";
import "./ActionsButtons.scss"
import {ReactComponent as Edit} from "../../../assets/edit.svg";
import {ReactComponent as Delete} from "../../../assets/delete.svg";

const ActionsButtons = ({onEdit, onDelete}) => {
    return (
        <div className="row-actions">
            <Button
                onClick={onEdit}
                variant="icon"
                buttonText={
                    <Icon icon={<Edit/>} className="action-icon"/>
                }
            />
            <Button
                onClick={onDelete}
                variant="icon"
                buttonText={
                    <Icon icon={<Delete/>} className="action-icon"/>
                }
            />
        </div>
    )
}

export default ActionsButtons