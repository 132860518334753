import axios from "axios";

export const loadUser = (id, callback) => {
    var config = {
        url: `/users/${id}`,
        method: 'GET',
    }

    axios.request(config).then(response => callback(response.data))
}

export const saveUser = (user, callback) => {
    var config = {
        url: user.id ? `/users/${user.id}` : "/users",
        method: user.id ? "PUT" : "POST",
        data: {user}
    }

    axios.request(config).then(response => callback())
}