import Card from "../../Layouts/Card";
import {ReactComponent as List} from "../../assets/list.svg";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useParams} from "react-router";
import * as actions from "../../components/Categories/CategoryActions";
import {FileInput, Form, Input, InputGroup, Label} from "../shared/FormComponents/Form";
import {useNavigate} from "react-router-dom";
import Icon from "../shared/Icon";
import {useEffect, useState} from "react";
import DeleteModal from "../shared/Modals/DeleteModal";
import "./CategoryPage.scss"
import * as _ from 'lodash'

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
});

const CategoryPage = () => {
    const {id} = useParams()
    const navigate = useNavigate();
    const [deleteModal, setDeleteModal] = useState(null)

    const formik = useFormik({
        initialValues: {
            id: null,
            name: "",
            photo: null
        },

        validationSchema,
        onSubmit: values => {
            actions.saveCategory({
                ..._.omit(values, "photo"),
                photo_id: _.has(values.photo, "id") ? values.photo.id : null,
            }, () => {
                navigate("/categories")
            });
        }
    });


    useEffect(() => {
        if (id) {
            actions.loadCategory(id, category => {
                formik.setValues({..._.omit(category, "photo")})
                if (_.has(category, "photo")) {
                    formik.setFieldValue("photo", {...category.photo})
                }
            })
        }
    }, [id])


    return (
        <Card
            title="Categories > "
            subtitle={id ? "Edit" : "New"}
            icon={<Icon icon={<List/>} className="list-icon"/>}
            onClick={() => formik.handleSubmit()}
            buttonText="Save"
            className="category"
        >
            <Form>
                <InputGroup>
                    <Label name="Name" isRequired/>
                    <Input
                        type="text"
                        name="name"
                        formik={formik}
                    />
                </InputGroup>
                <InputGroup>
                    <Label name="Photo" alignTextStart/>
                    <FileInput
                        onDelete={() => {
                            setDeleteModal({
                                name: formik.values.photo.name,
                                action: "Delete"
                            })
                        }}
                        formik={formik}
                    />
                </InputGroup>
            </Form>
            {deleteModal &&
                <DeleteModal
                    onDelete={() => {
                        formik.setFieldValue("photo", null)
                        setDeleteModal(null)
                    }}
                    onCancel={() => {
                        setDeleteModal(null)
                    }}
                    itemName={deleteModal.name}
                    actionName={deleteModal.action}
                />

            }
        </Card>
    );
};

export default CategoryPage;
