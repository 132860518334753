import "./Navbar.scss";
import {ReactComponent as Arrow} from "../assets/arrow.svg";
import {ReactComponent as Mortar} from "../assets/mortar.svg";
import {ReactComponent as Group} from "../assets/group.svg";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import Icon from "../components/shared/Icon";
import DefaultProfile from "../assets/defaultProfile.jpg"
import Button from "../components/shared/Button";
import * as _ from "lodash";
import classNames from "classnames";

const Navbar = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="navbar">
            {user.role === "user" &&
                <NavLink
                    to="/recipes"
                    className={classNames("link", {active: location.pathname === "/recipes" || location.pathname.startsWith("/recipe")})}
                >
                    <Icon icon={<Mortar/>} className="mortar-icon"/> Recipes
                </NavLink>
            }
            {user.role === "admin" &&
                <NavLink
                    to="/users"
                    className={classNames("link", {active: location.pathname === "/users" || location.pathname.startsWith("/user")})}
                >
                    <Icon icon={<Group/>} className="group-icon"/> Users
                </NavLink>
            }
            {user.role === "user" &&
                <div className="dropdown">
                    <div className="settings">
                        <span>Settings</span> <Icon icon={<Arrow/>} className="arrow-icon"/>
                    </div>
                    <div className="dropdown-menu">
                        <ul>
                            <li>
                                <Link to={"/categories"}>Categories</Link>
                            </li>
                            <li>
                                <Link to={"/tags"}>Tags</Link>
                            </li>
                            <li>
                                <Link to={"/ingredients"}>Ingredients</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            }
            <div className="profile">
                <div className="dropdown-profile-toggler">
                    <img src={user && _.has(user, "image_url") ? user.image_url : DefaultProfile} alt="avatar" className="avatar"/>
                    <span className="user-name">{user.name}</span>
                    <Icon icon={<Arrow/>} className="arrow-icon"/>
                </div>
                <div className="dropdown-profile-menu">
                    <ul>
                        <li>
                            <Button buttonText="Logout" variant="profile-dropdown" onClick={() => {
                                localStorage.removeItem("token")
                                localStorage.removeItem("user")
                                navigate("/")
                            }}/>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
