import axios from "axios";

export const loadCategory = (id, callback) => {
    const config = {
        method: "GET",
        url: `/categories/${id}`,
    }

    axios.request(config).then(response => {
        callback(response.data);
    })
}

export const saveCategory = (category, callback) => {
    var config = {
        url: category.id ? `/categories/${category.id}` : "/categories",
        method: category.id ? "PUT" : "POST",
        data: {category}
    };
    axios.request(config).then(() => {
        callback();
    })
};