import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import {client} from 'scrumship-client';

import appConfig from "./config/applicationConfiguration";

import "./index.scss"
import App from "./App";
import {BrowserRouter} from "react-router-dom";

axios.defaults.baseURL = appConfig.apiUrl;
axios.defaults.headers['Accept'] = 'application/json';
axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = token;
    }
    return config;
})
client.log('lXruWYU');

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
