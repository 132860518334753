import Modal from "./ModalTemplate/Modal";
import {Form, Input, InputGroup, Label} from "../FormComponents/Form";
import Button from "../Button";
import {useEffect} from "react";
import {useFormik} from "formik";
import FormSelect from "../FormComponents/FormSelect";
import * as Yup from "yup";
import * as _ from "lodash";

const validationSchema = Yup.object().shape({
    amount: Yup.string().required("Amount is required"),
    ingredient: Yup.object().shape({
        value: Yup.number().required("Ingredient is required"),
    })
})

const RecipeIngredientModal = ({recipeIngredient, onCancel, onSave, ingredientsOptions}) => {

    useEffect(() => {
        if (_.has(recipeIngredient, "amount") && _.has(recipeIngredient, "ingredient")) {
            formik.setValues({...recipeIngredient})
        }
    }, [recipeIngredient]);

    const formik = useFormik({
        initialValues: {
            id: null,
            amount: "",
            ingredient: "",
            isNew: true,
        },
        validationSchema,
        onSubmit: (values) => {
            onSave({
                ..._.omit(values, "ingredient"),
                ...values.ingredient,
                name: values.ingredient.label,
                ingredient_id: values.ingredient.value,
            })
        }
    })

    return (
        <Modal
            actionName="Ingredient"
            name={formik.values.isNew ? "New" : "Edit"}
            onCancel={onCancel}
            button={<Button variant="primary" buttonText="Save" onClick={() => formik.handleSubmit()}/>}
            className="recipe-ingredient"
        >
            <Form className="recipe-ingredient">
                <InputGroup>
                    <Label name="Ingredient" isRequired/>
                    <FormSelect name="ingredient" formik={formik} selectOptions={ingredientsOptions}/>
                </InputGroup>
                <InputGroup>
                    <Label name="Amount" isRequired/>
                    <Input name="amount" formik={formik} type="text"/>
                </InputGroup>
            </Form>
        </Modal>
    )
}

export default RecipeIngredientModal