import "./Modal.scss";
import Button from "../../Button";
import classNames from "classnames";

const Modal = ({actionName, name, onCancel, children, showButtonCancel = true, button, className}) => {

    const classNameForModal = classNames("modal", {
        [className]: className,
    })

    return (
        <div className={classNameForModal}>
            <div className="content">
                <div className="header">
                    <span>
                        {actionName} <span className="bold"> > {name}</span>
                    </span>
                </div>
                <div className="message">{children}</div>
                <div className="buttons">
                    <Button onClick={onCancel} buttonText="Cancel" type="button" variant="cancel" hidden={!showButtonCancel}/>
                    {button}
                </div>
            </div>
        </div>
    );
};

export default Modal;
