import "./Form.scss";
import InputGroup from "./InputGroup";
import Label from "./Label";
import Input from "./Input";
import FileInput from "./FileInput";
import FormTable from "./FormTable";
import Textarea from "./Textarea";
import classNames from "classnames";



const Form = ({children, className}) => {

    return (
        <form className={classNames("form", {[className]: className})}>{children}</form>
    )
};

export {Form, InputGroup, Label, Input, FileInput, FormTable, Textarea};
