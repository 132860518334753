import axios from "axios";

export const login = (user, callback) => {
    const config = {
        url: '/users/sign_in',
        method: 'POST',
        data: {user}
    }

    axios.request(config).then((response) => {
        callback({
            token: response.headers.authorization,
            ...response.data
        }, undefined)
    }).catch(error => callback(undefined, error));
}