import "./Card.scss";
import Button from "../components/shared/Button";
import classNames from "classnames";

const Card = ({children, onClick, buttonText, title, subtitle, icon, className}) => {
    const classNameForCard = classNames("card", {
        [className]: className,
    })

    return (
        <div className={classNameForCard}>
            <div className="breadcrumbs">
                {icon}
                {title} <span className="active"> {subtitle}</span>
                <Button buttonText={buttonText} onClick={onClick} variant="primary"/>
            </div>
            <div className="content">{children}</div>
        </div>
    );
};

export default Card;
