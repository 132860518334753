import Card from "../../Layouts/Card";
import {useParams} from "react-router";
import Icon from "../shared/Icon";
import {ReactComponent as Mortar} from "../../assets/mortar.svg";
import './RecipePage.scss'
import {useFormik} from "formik";
import {FileInput, Form, FormTable, Input, InputGroup, Label, Textarea} from "../shared/FormComponents/Form";
import FormSelect from "../shared/FormComponents/FormSelect";
import {useEffect, useState} from "react";
import * as Yup from "yup";
import * as actions from "./RecipeActions"
import * as _ from "lodash";
import DeleteModal from "../shared/Modals/DeleteModal";
import RecipeIngredientModal from "../shared/Modals/RecipeIngredientModal";
import {useNavigate} from "react-router-dom";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    preparation_time: Yup.number().required("Preparation time is required"),
    category: Yup.object().shape({
        value: Yup.number().required("Category is required"),
    })
})

const RecipePage = () => {
    const {id} = useParams();
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [deleteModal, setDeleteModal] = useState(null)
    const [ingredientModal, setIngredientModal] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        actions.loadTags(tags => {
            setTags(tags.map(tag => ({value: tag.id, label: tag.name})))
        })
        actions.loadCategories(categories => {
            setCategories(categories.map(category => ({value: category.id, label: category.name})))
        })
        actions.loadIngredients(ingredients => {
            console.log(ingredients)
            setIngredients(ingredients.map(ingredient => ({
                value: ingredient.id,
                label: ingredient.name,
                unit: ingredient.unit,
                image_url: _.has(ingredient, "photo") ? ingredient.photo.image_url : null,
            })))
        })
    }, []);


    const formik = useFormik({
        initialValues: {
            id: null,
            name: "",
            description: "",
            preparation_time: "",
            category: "",
            tags: [],
            recipe_ingredients: [],
            recipe_ingredients_to_delete: [],
            photo: null
        },
        validationSchema,
        onSubmit: (values) => {
            actions.saveRecipe({
                ..._.omit(values, ["recipe_ingredients", "tags", "category", "photo", "recipe_ingredients_to_delete"]),
                category_id: values.category.value,
                photo_id: _.has(values.photo, "id") ? values.photo.id : null,
                recipes_ingredients_attributes: [...values.recipe_ingredients, ...values.recipe_ingredients_to_delete],
                tag_ids: values.tags.map(tag => tag.value)
            }, () => {
                navigate("/recipes")
            })
        }
    })

    useEffect(() => {
        if (id) {
            actions.loadRecipe(id, (recipe) => {
                formik.setValues({
                    ..._.omit(recipe, "photo"),
                    recipe_ingredients_to_delete: []
                })
                if (_.has(recipe, "photo")) {
                    formik.setFieldValue("photo", {...recipe.photo})
                }
            });
        }
    }, [id]);

    const handleDeleteIngredient = ingredientIndex => {
        let existingRecipeIngredientToDelete = null;

        const recipeIngredient = formik.values.recipe_ingredients.map((existingIngredient, index) => {
                if (index === ingredientIndex) {
                    if (!existingIngredient.isNew) {
                        existingRecipeIngredientToDelete = {id: existingIngredient.id, _destroy: existingIngredient.id.toString()}
                    }

                    return null
                }

                return existingIngredient
            }
        ).filter(Boolean)

        formik.setFieldValue("recipe_ingredients", recipeIngredient)

        if (existingRecipeIngredientToDelete) {
            formik.setFieldValue("recipe_ingredients_to_delete",
                [...formik.values.recipe_ingredients_to_delete, existingRecipeIngredientToDelete]
            )
        }
    }

    const handleEditIngredient = ingredientIndex => {
        const recipeIngredientToEdit = formik.values.recipe_ingredients[ingredientIndex]
        if (recipeIngredientToEdit) {
            setIngredientModal({
                ..._.omit(recipeIngredientToEdit, ["ingredient_id", "name", "unit", "image_url"]),
                ingredient: {
                    ..._.pick(recipeIngredientToEdit, ["unit", "image_url"]),
                    value: recipeIngredientToEdit.ingredient_id,
                    label: recipeIngredientToEdit.name,
                }
            })
        }
    }

    const handleSaveIngredient = ingredient => {
        const isExisting = formik.values.recipe_ingredients.find(recipeIngredient => recipeIngredient.ingredient_id === ingredient.ingredient_id)
        let newIngredients = []

        if (isExisting) {
            newIngredients = formik.values.recipe_ingredients.map((existingIngredient) =>
                existingIngredient.ingredient_id === ingredient.value ?
                    {...ingredient} : existingIngredient
            )
        } else {
            newIngredients = [...formik.values.recipe_ingredients, ingredient]
        }

        formik.setFieldValue("recipe_ingredients", newIngredients)
        setIngredientModal(null)
    }


    return (
        <Card
            title="Recipes"
            subtitle={id ? "> Edit" : "> New"}
            icon={<Icon icon={<Mortar/>} className="mortar-icon"/>}
            className="recipe-page"
            buttonText="Save"
            onClick={() => formik.handleSubmit()}
        >
            <Form>
                <InputGroup>
                    <Label name="Name" isRequired/>
                    <Input name="name" formik={formik} type="text"/>
                </InputGroup>
                <InputGroup>
                    <Label name="Description" isRequired alignTextStart/>
                    <Textarea name="description" formik={formik}/>
                </InputGroup>
                <InputGroup className="preparation-time">
                    <Label name="Preparation time" isRequired/>
                    <Input name="preparation_time" formik={formik} type="number"/>
                    <span>min</span>
                </InputGroup>
                <InputGroup>
                    <Label name="Category" isRequired/>
                    <FormSelect name="category" formik={formik} selectOptions={categories}/>
                </InputGroup>
                <InputGroup>
                    <Label name="Tag"/>
                    <FormSelect isMulti name="tags" formik={formik} selectOptions={tags}/>
                </InputGroup>
                <InputGroup>
                    <Label name="Ingredients" alignTextStart/>
                    <FormTable
                        recipeIngredients={formik.values.recipe_ingredients}
                        onDelete={(recipeIngredientId) => handleDeleteIngredient(recipeIngredientId)}
                        onEdit={(recipeIngredientId) => handleEditIngredient(recipeIngredientId)}
                        onAdd={() => setIngredientModal({})}
                    />
                </InputGroup>
                <InputGroup>
                    <Label name="Photo" alignTextStart/>
                    <FileInput
                        onDelete={() => {
                            setDeleteModal({
                                name: formik.values.photo.name,
                                action: "Delete",
                            })
                        }}
                        formik={formik}
                    />
                </InputGroup>
            </Form>
            {
                deleteModal &&
                <DeleteModal
                    onDelete={() => {
                        formik.setFieldValue("photo", null)
                        setDeleteModal(null)
                    }}
                    onCancel={() => {
                        setDeleteModal(null)
                    }}
                    itemName={deleteModal.name}
                    actionName={deleteModal.action}
                />
            }
            {
                ingredientModal &&
                <RecipeIngredientModal
                    recipeIngredient={ingredientModal}
                    onSave={(ingredient) => {
                        handleSaveIngredient(ingredient)
                    }}
                    ingredientsOptions={ingredients.filter(ingredient =>
                        !formik.values.recipe_ingredients.some(recipeIngredient => recipeIngredient.ingredient_id === ingredient.value)
                    )
                    }
                    onCancel={() => setIngredientModal(null)}
                />
            }
        </Card>
    )
}

export default RecipePage;