import "./Button.scss";
import classNames from "classnames";

const Button = ({variant, buttonText, onClick, type = "button"}) => {
    const className = classNames("button", {
        [variant]: variant,
    });

    return <button type={type} onClick={onClick} className={className}>{buttonText}</button>;
};

export default Button;
