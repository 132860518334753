import "./ThumbnailWithText.scss"
import DefaultImage from '../../../assets/default-image.jpg'
import DefaultProfilePicture from '../../../assets/defaultProfile.jpg'

const ThumbnailWithText = ({imgSrc, text, isUserAvatar = false}) => {
    return (
        <div className="cell-with-img-and-text">
            <img alt="asset" className="image" src={imgSrc ? imgSrc : (isUserAvatar ? DefaultProfilePicture : DefaultImage)}/>
            {text}
        </div>
    )
}

export default ThumbnailWithText;