import axios from "axios";


export const loadCategories = (callback) => {
    const config = {
        url: "/categories",
        method: "GET",
    }
    axios.request(config).then(response => {
        callback(response.data);
    })
}

export const deleteCategory = (id, callback) => {
    const config = {
        method: "DELETE",
        url: `/categories/${id}`,
    }

    axios.request(config).then(() => {
        callback();
    }).catch(error => {
        const {message} = error.response.data;
        const {status} = error.response;

        if (message && status === 422) {
            callback(message);
        } else {
            callback()
        }
    })
};


