import Table from "../Table/Table";
import './FormTable.scss'
import {useMemo} from "react";
import ActionsButtons from "../Table/ActionsButtons";
import Button from "../Button";
import DefaultImage from "../../../assets/default-image.jpg"

const FormTable = ({recipeIngredients, onAdd, onEdit, onDelete}) => {
    const columns = useMemo(() => [
        {
            Header: "Photo",
            accessor: "image_url",
            Cell: cell => {
                return <img src={cell.value ? cell.value : DefaultImage} alt="ingredient" className="image"/>
            }
        },
        {
            Header: "Ingredient",
            accessor: "name"
        },
        {
            Header: "Amount",
            accessor: "amount"
        },
        {
            Header: "Units",
            accessor: "unit"
        },
        {
            Header: "Options",
            accessor: "id",
            Cell: ({row}) => {
                return <ActionsButtons
                    onDelete={() => {
                        onDelete(row.index)
                    }}
                    onEdit={() => {
                        onEdit(row.index)
                    }}/>
            }
        }
    ], [recipeIngredients])


    return (
        <div className="form-table">
            {recipeIngredients.length !== 0 && <Table data={recipeIngredients} columns={columns} className="editable-ingredients-table"/>}
            <Button
                type="button"
                buttonText="+ Add new"
                onClick={() => onAdd()}
                variant="table-button"
            />
        </div>
    )
}

export default FormTable;