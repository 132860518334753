import Card from "../../Layouts/Card";
import {useParams} from "react-router";
import Icon from "../shared/Icon";
import {ReactComponent as Tag} from "../../assets/tag.svg";
import {Form, Input, InputGroup, Label} from "../shared/FormComponents/Form";
import {useFormik} from "formik";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import * as actions from "./TagAction"
import {useEffect} from "react";
import "./TagPage.scss"

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
});

const TagPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            id: null,
            name: "",
        },
        validationSchema,
        onSubmit: (values) => {
            actions.saveTag({...values}, () => {
                navigate("/tags")
            })
        }
    })

    useEffect(() => {
        if (id) {
            actions.loadTag(id, tag => {
                formik.setValues({...tag})
            })
        }
    }, [id])


    return (
        <Card
            icon={<Icon icon={<Tag className="tag-icon"/>}/>}
            onClick={() => {
                formik.handleSubmit()
            }}
            buttonText="Save"
            title="Tags"
            subtitle={id ? "> Edit" : "> New"}
            className="tag-page"
        >
            <Form>
                <InputGroup>
                    <Label name="Name" isRequired/>
                    <Input
                        type="text"
                        name="name"
                        formik={formik}
                    />
                </InputGroup>
            </Form>
        </Card>
    )
}

export default TagPage;