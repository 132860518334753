import Card from "../../Layouts/Card";
import {useEffect, useMemo, useState} from "react";
import "./CategoriesPage.scss";
import {ReactComponent as List} from "../../assets/list.svg";
import Table from "../shared/Table/Table";
import {useNavigate} from "react-router-dom";
import * as actions from "./CategoriesAction";
import Icon from "../shared/Icon";
import ThumbnailWithText from "../shared/Table/ThumbnailWithText";
import ActionsButtons from "../shared/Table/ActionsButtons";
import DeleteModal from "../shared/Modals/DeleteModal";
import ErrorModal from "../shared/Modals/ErrorModal";
import * as _ from "lodash";

const CategoriesPage = () => {
    const [categories, setCategories] = useState([]);
    const [deleteModal, setDeleteModal] = useState(null);
    const [contentErrorModal, setContentErrorModal] = useState(null);
    const navigate = useNavigate();


    const handleDelete = (cellData) => {
        const id = cellData.value;
        const category = cellData.data.find(c => c.id === id);
        setDeleteModal({id, name: category.name, action: "Delete"});
    }

    useEffect(() => {
        actions.loadCategories(categories => setCategories(categories));
    }, [])


    const columns = useMemo(
        () => [
            {
                Header: "Category",
                accessor: "name",
                Cell: cell => {
                    const photo = cell.row.original["photo"]
                    return <ThumbnailWithText text={cell.value} imgSrc={photo ? photo["image_url"] : null}/>
                }
            },
            {
                Header: "Options",
                accessor: "id",
                Cell: cell => {
                    return <ActionsButtons
                        onEdit={() => navigate(`/category/${cell.value}`)}
                        onDelete={() => handleDelete(cell)}
                    />
                }
            }
        ],
        []
    );

    return (
        <Card
            subtitle="Categories"
            buttonText="+ Add new"
            className="categories"
            onClick={() => navigate("/category")}
            icon={<Icon icon={<List/>} className="list-icon"/>}
        >
            <Table data={categories} columns={columns}/>
            {_.has(deleteModal, "id") && <DeleteModal
                onDelete={() => {
                    actions.deleteCategory(deleteModal.id, (error) => {
                        if (error) {
                            setDeleteModal(prevState => ({
                                name: prevState.name,
                                action: prevState.action,
                            }))
                            setContentErrorModal(error)
                        } else {
                            setCategories(prevState => prevState.filter(category => category.id !== deleteModal.id))
                            setDeleteModal(null);
                        }
                    });
                }}
                itemName={deleteModal.name}
                onCancel={() => {
                    setDeleteModal(null);
                }}
                actionName={deleteModal.action}
            />}
            {
                contentErrorModal && <ErrorModal
                    actionName={deleteModal.action}
                    itemName={deleteModal.name}
                    onCancel={() => {
                        setContentErrorModal(null);
                    }}
                >
                    {contentErrorModal}
                </ErrorModal>
            }
        </Card>
    );
};

export default CategoriesPage;
