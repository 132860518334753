import "./FileInput.scss";
import {useDropzone} from "react-dropzone";
import Icon from "../Icon";
import {ReactComponent as UploadFile} from "../../../assets/upload-file.svg";
import {savePhoto} from "./photoAction";
import Button from "../Button";
import * as _ from "lodash";


const FileInput = ({onDelete, formik}) => {

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0]
        const formData = new FormData();
        formData.append("image", file);
        savePhoto(formData, (photo) => {
            formik.setFieldValue("photo", {...photo})
        })
    }


    const {getRootProps, getInputProps} = useDropzone({
        onDrop, maxFiles: 1,
        noClick: _.has(formik.values.photo, "id")
    });

    return (
        <div className="file-input">
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                {formik.values.photo ?
                    <div className="image-container">
                        <img src={formik.values.photo["image_url"]} alt="category" className="image"/>
                        <Button onClick={() => onDelete()} buttonText="Remove" variant="photo-overlay"/>
                    </div>
                    : (<>
                        <div className="circle">
                            <Icon icon={<UploadFile/>} className="upload-file-icon"/>
                        </div>
                        <p>
                            <span>Click to Upload</span> or drag and drop
                        </p>
                        <p>(Max. File size: 30MB)</p>
                    </>)
                }
            </div>
        </div>
    );
};

export default FileInput;
