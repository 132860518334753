import Card from "../../Layouts/Card";
import "./UserPage.scss"
import {ReactComponent as Group} from "../../assets/group.svg";
import * as actions from './UserActions'
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {FileInput, Form, InputGroup, Label} from "../shared/FormComponents/Form";
import Input from "../shared/FormComponents/Input";
import FormSelect from "../shared/FormComponents/FormSelect";
import * as Yup from "yup";
import DeleteModal from "../shared/Modals/DeleteModal";
import * as _ from "lodash";
import classNames from "classnames";
import Icon from "../shared/Icon";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    show_passwords: Yup.boolean().default(true),
    password: Yup.string().when("show_passwords", {
        is: true,
        then: (schema) => schema.required("Password is required"),
        otherwise: (schema) => schema.notRequired()
    }).min(6, 'Password must be at least 6 characters'),
    confirm_password: Yup.string().when("show_passwords", {
        is: true,
        then: (schema) => schema.required("Confirm password is required")
            .oneOf([Yup.ref('password')], "Passwords must match"),
        otherwise: (schema) => schema.notRequired()
    }),
    role: Yup.object().shape({
        value: Yup.string().required("Role is required"),
    }),
})

const rolesOptions = [
    {
        label: "User",
        value: "user",
    },
    {
        label: "Admin",
        value: "admin",
    }
]


const UserPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [deleteModal, setDeleteModal] = useState(null)

    const formik = useFormik({
        initialValues: {
            id: null,
            name: "",
            surname: "",
            email: "",
            password: "",
            confirm_password: "",
            role: "",
            photo: "",
            show_passwords: true,
            previousName: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            actions.saveUser({
                ..._.omit(values, ["confirm_password", "photo", "role", "change_password", "previousName"]),
                photo_id: _.has(values.photo, "id") ? values.photo.id : null,
                role: values.role.value,
            }, () => navigate("/users"));
        }
    })


    useEffect(() => {
        if (id) {
            actions.loadUser(id, (user) => {
                formik.setValues({
                    ..._.omit(user, ["role", "photo"]),
                    previousName: `${user.name} ${user.surname}`,
                    role: {
                        value: user.role,
                        label: user.role.charAt(0).toUpperCase() + user.role.slice(1)
                    },
                    show_passwords: false,
                })
                if (_.has(user, "photo")) {
                    formik.setFieldValue("photo", {...user.photo})
                }
            })
        }
    }, [id]);


    return (
        <Card
            icon={<Icon icon={<Group/>} className="group-icon"/>}
            className="user-page"
            buttonText="Save"
            subtitle={id ? `> ${formik.values.previousName}` : "> New"}
            onClick={() => formik.handleSubmit()}
            title="Users"
        >
            <Form className="user-form">
                <InputGroup>
                    <Label name="Name" isRequired/>
                    <Input type="text" name="name" formik={formik}/>
                </InputGroup>
                <InputGroup>
                    <Label name="Surname"/>
                    <Input type="text" name="surname" formik={formik}/>
                </InputGroup>
                <InputGroup>
                    <Label name="Email" isRequired/>
                    <Input type="email" name="email" formik={formik}/>
                </InputGroup>
                {formik.values.show_passwords &&
                    <div className={classNames({"align-end": id})}>
                        <InputGroup>
                            <Label name="Password" isRequired/>
                            <Input type="password" name="password" formik={formik}/>
                        </InputGroup>
                        <InputGroup>
                            <Label name="Confirm Password" isRequired/>
                            <Input type="password" name="confirm_password" formik={formik}/>
                        </InputGroup>
                    </div>
                }
                <InputGroup>
                    <Label name="Role" isRequired/>
                    <FormSelect formik={formik} name="role" selectOptions={rolesOptions}/>
                </InputGroup>
                <InputGroup>
                    <Label name="Profile Picture" alignTextStart/>
                    <FileInput formik={formik} onDelete={() => setDeleteModal({
                        name: formik.values.photo.name,
                        action: "Delete"
                    })}/>
                </InputGroup>
                {
                    id &&
                    <InputGroup>
                        <Label name="Change password"/>
                        <Input type="checkbox" formik={formik} name="show_passwords"/>
                    </InputGroup>
                }
            </Form>
            {
                deleteModal &&
                <DeleteModal
                    onDelete={() => {
                        formik.setFieldValue("photo", null)
                        setDeleteModal(null)
                    }}
                    onCancel={() => setDeleteModal(null)}
                    itemName={deleteModal.name}
                    actionName={deleteModal.action}
                />
            }
        </Card>
    )
}

export default UserPage