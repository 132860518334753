import classNames from "classnames";
import "./Textarea.scss"

const Textarea = ({formik, name}) => {

    const validationStatus = formik.touched[name] && formik.errors[name]

    const classNamesForTextArea = classNames({
        "validation-error": validationStatus,
    })


    return (
        <textarea
            name={name}
            className={classNamesForTextArea}
            onChange={formik.handleChange}
            onBlur={formik.onBlur}
            value={formik.values[name]}
            placeholder={validationStatus ? formik.errors[name] : null}
        />
    )
}

export default Textarea