import Modal from "./ModalTemplate/Modal";

const ErrorModal = ({ actionName, itemName, onCancel, children }) => {
  return (
    <Modal actionName={actionName} name={itemName} onCancel={onCancel}>
      {children}
    </Modal>
  );
};

export default ErrorModal;
