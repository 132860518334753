import {Route, Routes, useLocation} from "react-router-dom";
import "./App.scss";
import CategoriesPage from "./components/Categories/CategoriesPage";
import CategoryPage from "./components/Categories/CategoryPage";
import TagsPage from "./components/Tags/TagsPage";
import TagPage from "./components/Tags/TagPage";
import IngredientsPage from "./components/Ingredients/IngredientsPage";
import IngredientPage from "./components/Ingredients/IngredientPage";
import RecipesPage from "./components/Recipes/RecipesPage";
import RecipePage from "./components/Recipes/RecipePage";
import LoginPage from "./components/Login/LoginPage";
import classNames from "classnames";
import Navbar from "./Layouts/Navbar";
import UsersPage from "./components/Users/UsersPage";
import UserPage from "./components/Users/UserPage";

function App() {
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem("user"));

    const classNamesForMain = classNames("main", {
        "login-page-main": location.pathname === "/",
    })


    return (
        <>
            {user && <Navbar/>}
            <div className={classNamesForMain}>
                <Routes>
                    <Route exact index path="/" Component={LoginPage}/>
                </Routes>
                {user && user.role === "user" &&
                    <Routes>
                        <Route exact path="/categories" Component={CategoriesPage}/>
                        <Route exact path="/category/:id?" Component={CategoryPage}/>
                        <Route exact path="/tags" Component={TagsPage}/>
                        <Route exact path="/tag/:id?" Component={TagPage}/>
                        <Route exact path="/ingredients" Component={IngredientsPage}/>
                        <Route exact path="/ingredient/:id?" Component={IngredientPage}/>
                        <Route exact path="/recipes" Component={RecipesPage}/>
                        <Route exact path="/recipe/:id?" Component={RecipePage}/>
                    </Routes>
                }
                {user && user.role === "admin" &&
                    <Routes>
                        <Route exact path="/users" Component={UsersPage}/>
                        <Route exact path="/user/:id?" Component={UserPage}/>
                    </Routes>
                }
            </div>
        </>
    );
}

export default App;
