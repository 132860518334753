import LoginImage from "../../assets/LoginImage.png"
import {Form, Input, InputGroup, Label} from "../shared/FormComponents/Form";
import './LoginPage.scss'
import {useFormik} from "formik";
import * as Yup from "yup";
import Button from "../shared/Button";
import * as actions from './LoginActions'
import {useEffect, useState} from "react";
import Icon from "../shared/Icon";
import {ReactComponent as Bell} from "../../assets/notifications.svg";
import {ReactComponent as Visibility} from "../../assets/visibility.svg";
import {useNavigate} from "react-router-dom";
import * as _ from "lodash";

const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Login is required"),
    password: Yup.string().required("Password is required"),
})

const LoginPage = () => {
    const [alert, setAlert] = useState(null)
    const [showPassword, setShowPassword] = useState(false)
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema,
        onSubmit: (values) => {
            actions.login({...values}, (user, error) => {
                if (error) {
                    setAlert(true)
                } else {
                    localStorage.setItem("token", user.token)
                    const stringifyUser = JSON.stringify({..._.omit(user, "token")})
                    localStorage.setItem("user", stringifyUser)
                    if (user.role === "admin") {
                        navigate("/users")
                    } else {
                        navigate("/recipes")
                    }
                }
            })
        }
    })


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"))
        if (user) {
            if (user.role === "admin") {
                navigate("/users")
            } else {
                navigate("/recipes")
            }
        }
    }, []);

    return (
        <div className="login-page">
            <img src={LoginImage} alt="login" className="image"/>
            <Form className="login-form">
                <h1>NICE TO SEE YOU!</h1>
                {alert &&
                    <div className="alert">
                        <Icon icon={<Bell/>} className="bell-icon"/> The email or password are incorrect. Please try again.
                    </div>
                }
                <InputGroup className="login-group">
                    <Label name="Login" className="login-label"/>
                    <Input name="email" formik={formik} type="text" className="login-input"/>
                </InputGroup>
                <InputGroup className="login-group">
                    <Label name="Password" className="login-label"/>
                    <Input name="password" type={showPassword ? "text" : "password"} formik={formik} className="login-input"/>
                    <Button variant="icon" buttonText={<Icon icon={<Visibility/>}/>}
                            onClick={() => setShowPassword(prevState => !prevState)}/>
                </InputGroup>
                <Button type="submit" variant="login-button" buttonText="Log In" onClick={formik.handleSubmit}/>
            </Form>
        </div>
    )
}

export default LoginPage