import axios from "axios";

export const savePhoto = (data, callback) => {
    const config = {
        data: data,
        method: "POST",
        url: "/photos",
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }

    axios.request(config).then(response => callback(response.data));
};
