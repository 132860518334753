import {useTable} from "react-table";
import React from "react";
import "./Table.scss";
import classNames from "classnames";

const Table = ({columns, data, className}) => {
    const {getTableBodyProps, headerGroups, rows, prepareRow} = useTable({columns, data});

    const classNameForTable = classNames("table", {
        [className]: className
    })

    return (
        <table className={classNameForTable}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th
                            {...column.getHeaderProps({
                                className: column.Header.toLowerCase()
                            })}
                        >
                            {column.render("Header")}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map(row => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                            <td {...cell.getCellProps({className: cell.column.Header.toLowerCase()})}>{cell.render("Cell")}</td>
                        ))}
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
};

export default Table;
