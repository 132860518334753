import axios from "axios";

export const loadTag = (id, callback) => {
    const config = {
        method: "GET",
        url: `/tags/${id}`,
    }

    axios.request(config).then(response => {
        callback(response.data);
    })
}

export const saveTag = (tag, callback) => {
    const config = {
        url: tag.id ? `/tags/${tag.id}` : "/tags",
        method: tag.id ? "PUT" : "POST",
        data: {tag}
    };

    axios.request(config).then(() => callback())
};