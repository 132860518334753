import axios from "axios";

export const loadRecipes = (callback) => {
    var config = {
        url: "/recipes",
        method: "GET",
    }

    axios.request(config).then(response => callback(response.data))
}

export const deleteRecipe = (id, callback) => {
    const config = {
        url: `/recipes/${id}`,
        method: "DELETE",
    }

    axios.request(config).then(() => callback())
}